import React from "react";
import "./projects.css";
import IMG1 from "../../assets/portfolio1.PNG";
import IMG2 from "../../assets/portfolio2.PNG";
import IMG3 from "../../assets/portfolio3.PNG";
import IMG4 from "../../assets/portfolio4.PNG";
import Build from "../../assets/Run_the_exe_to_play.zip";

const Projects = () => {
  return (
    <section id="projects" className="proj">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
          <h3>WebSocialize</h3>
          <h4>Thread-based social media app, backend focused.</h4>
          <h5>Django, Python, PostgreSQL, HTML, CSS, JavaScript</h5>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/brandonwernham/websocialize"
              className="btn"
              target="_blank"
            >
              Github
            </a>
            <a
              href="https://web-production-a14b.up.railway.app/"
              className="btn btn-primary"
              target="_blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG3} alt="" />
          </div>
          <h3>Personal Ecommerce Store</h3>
          <h4>Full-stack ecommerce store featuring my custom PC builds.</h4>
          <h5>
            Next.js, TypeScript, Redux, MongoDB, Tailwind, CMS, Stripe API,
            Google API, HTML
          </h5>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/brandonwernham/brandonw-ecommerce"
              className="btn"
              target="_blank"
            >
              Github
            </a>
            <a
              href="https://brandonw-ecommerce.vercel.app/"
              className="btn btn-primary"
              target="_blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
          <h3>StudyNote</h3>
          <h4>The easiest way to share and find school notes.</h4>
          <h5>MySQL, Node.js, Express.js, React, JavaScript, HTML, CSS</h5>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/brandonwernham/StudyNote"
              className="btn"
              target="_blank"
            >
              Github
            </a>
            <a
              href="https://studynote.ca/"
              className="btn btn-primary"
              target="_blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
          <h3>Spotify Playlist AI</h3>
          <h4>
            Use various prompts to get a unique AI generated Spotify playlist.
          </h4>
          <h5>React, JavaScript, HTML, CSS, Spotify API, OpenAI API</h5>
          <div className="portfolio__item-cta">
            <a
              href="https://spotifyplaylistai.com/"
              className="btn btn-primary"
              target="_blank"
            >
              Live Demo
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Projects;
